import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    localise: true,
    inputProps: {
      required: true,
      label: "Subject name",
      placeholder: "Name of your subject.",
      name: "subjectName",
    },
  },
  about: {
    localise: true,
    inputProps: {
      label: "About your subject",
      name: "about",
      required: true,
    },
    mainHelperText:
      "Short description of your organization and its activities.",
  },
  extraInfo: {
    localise: true,
    inputProps: {
      label: "Extra info",
      name: "extraInfo",
    },
    mainHelperText:
      "Text related to additional information to be given to the visitor before arriving at the location. For example, the information may consist of the following: safety measures, information on when the visitor should arrive at the location, instructions to visitors about the rules of behavior or dress at the location, information about the existence of a cloakroom at the location, allowed items on location (backpack, bags)",
  },
  openingHoursInfo: {
    localise: true,
    inputProps: {
      label: "Opening hours info",
      name: "openingHoursInfo",
    },
    mainHelperText:
      "Any additional information about working hours. For example, does the museum work on holidays?",
  },
  gallery: {
    inputProps: {
      label: "Gallery",
      required: true,
      name: "gallery",
    },
    rule: "media",
    mainLocaleRequiredOnly: true,
    localise: true,
  },
});
