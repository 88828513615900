import { MAIN_ISO_LANGUAGE_CODE } from "../../../utils";
import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      name: "title",
      label: "Title",
      placeholder: "A catchy title",
      required: true,
    },
    mainHelperText:
      "Do not include Checkpoint numbering. It is added automatically.",
    localise: true,
  },
  description: {
    localise: true,
    mainHelperText:
      "This short description describes the general theme and what the visitors can expect when playing the Interactive tour.",
    inputProps: {
      name: "description",
      label: "Description",
      placeholder: "A short description of this interactive tour",
      required: true,
    },
  },
  introduction: {
    localise: true,
    mainHelperText:
      "The Introduction of Interactive tour serves as a brief summary of its entire Narrative.",
    inputProps: {
      name: "interactiveTourIntroduction",
      label: "Interactive tour Introduction.",
      placeholder: "",
      required: true,
    },
  },
  conclusion: {
    localise: true,
    mainHelperText:
      "The Conclusion of Interactive Tour serves as a brief epilogue of its entire Narrative.",
    inputProps: {
      name: "interactiveTourConclusion",
      label: "Interactive tour Conclusion",
      placeholder: "",
      required: true,
    },
  },
  startingPoint: {
    localise: true,
    mainHelperText: "Where is the starting point of the first checkpoint?",
    inputProps: {
      name: "startingPoint",
      label: "Starting point",
      placeholder: "Reception desk",
      required: true,
    },
  },
  importantInfo: {
    localise: true,
    mainHelperText: "Add some important information here.",
    inputProps: {
      name: "importantInformation",
      label: "Important information",
      placeholder: "Played in and around the museum",
    },
  },
  duration: {
    inputProps: {
      name: "duration",
      label: "Duration",
      placeholder: "30",
      required: true,
    },
    regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
    mainHelperText:
      "On average, how long is it required to finish the Interactive tour in minutes.",
  },
  difficulty: {
    inputProps: {
      name: "difficulty",
      label: "Difficulty",
      required: true,
    },
    mainHelperText: "How hard is it to finish the tour?",
  },
  gallery: {
    localise: true,
    inputProps: {
      name: "gallery",
      label: "Gallery",
      required: true,
    },
    mainHelperText: `Specify media files that will be displayed to describe the Interactive tour in the selected language. If not specified, ${MAIN_ISO_LANGUAGE_CODE} images will be used.`,
    rule: "media",
    mainLocaleRequiredOnly: true,
  },
  ageAppropriate: {
    inputProps: {
      name: "ageAppropriate",
      label: "Age appropriate",
      required: true,
    },
    mainHelperText: "For what age group is this content suitable?",
  },
});
