import { Reference, useMutation, useQuery } from "@apollo/client";
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material";
import {
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import {
  DELETE_ADMIN,
  IDeleteAdminData,
  IDeleteAdminVars,
  IResendPersonInvitationData,
  IResendPersonInvitationVars,
  IRevokePersonInvitationData,
  IRevokePersonInvitationVars,
  RESEND_PERSON_ROLE_SUBJECT_INVITATION,
  REVOKE_PERSON_ROLE_SUBJECT_INVITATION,
} from "../../../apollo/mutations";
import {
  ALL_ADMINISTRATORS,
  ALL_PERSON_INVITATIONS,
  IAdminsData,
  IPersonInvitationsData,
} from "../../../apollo/queries";
import {
  PageLayout,
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../components";
import { DeleteDialog } from "../../../components/deleteDialog/DeleteDialog";
import { IDialogContext } from "../../../utils";
import { useGlobalStyles } from "../../../utils/theme";
import { useStyles } from "./AdministratorsPage.styles";
import {
  UpsertAdminDialog,
  AdministratorItem,
  InvitationItem,
} from "./components";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const AdministratorsPage: FC = () => {
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState("1");
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      open: false,
      id: undefined,
    }));
  };

  const handleOpenCreateDialog = () => {
    setOpenDialog({
      type: "create",
      id: undefined,
      open: true,
    });
  };

  const { data, loading, error } = useQuery<IAdminsData, null>(
    ALL_ADMINISTRATORS,
    {
      onError: (err) => {
        enqueueSnackbar(`Failed to fetch data: ${err}`, {
          variant: "error",
        });
      },
    }
  );

  const {
    data: dataInvitations,
    loading: loadingInvitations,
    error: errorInvitations,
  } = useQuery<IPersonInvitationsData, null>(ALL_PERSON_INVITATIONS, {
    onError: (err) => {
      enqueueSnackbar(`Failed to fetch data: ${err}`, {
        variant: "error",
      });
    },
  });

  const [deleteAdminMutation, { loading: loadingDeleteMutation }] = useMutation<
    IDeleteAdminData,
    IDeleteAdminVars
  >(DELETE_ADMIN, {
    onCompleted: (res) => {
      enqueueSnackbar("Admin deleted!", { variant: "success" });
      handleCloseDialog();
    },
    onError: (error) => {
      // console.log({ error });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allAdministrators(existingAdmins: Array<Reference>, { readField }) {
            if (data) {
              return existingAdmins.filter(
                (taskRef) => data.deleteAdmin.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const [
    revokePersonInvitationMutation,
    { loading: loadingRevokePersonInvitation },
  ] = useMutation<IRevokePersonInvitationData, IRevokePersonInvitationVars>(
    REVOKE_PERSON_ROLE_SUBJECT_INVITATION,
    {
      onCompleted: () => {
        enqueueSnackbar("Invitation has been revoked!", {
          variant: "success",
        });
        handleCloseDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    resendPersonInvitationMutation,
    { loading: loadingResendPersonInvitation },
  ] = useMutation<IResendPersonInvitationData, IResendPersonInvitationVars>(
    RESEND_PERSON_ROLE_SUBJECT_INVITATION,
    {
      onCompleted: () => {
        enqueueSnackbar("New invitation has been sent!", {
          variant: "success",
        });
        handleCloseDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      refetchQueries: [ALL_PERSON_INVITATIONS],
    }
  );

  const handleDeleteAdmin = () => {
    if (openDialog.id && openDialog.type === "delete") {
      deleteAdminMutation({ variables: { id: +openDialog.id } });
    } else {
      enqueueSnackbar("Failed to call delete mutation");
    }
  };

  const handleRevokeAdminInvitation = () => {
    if (openDialog.id) {
      revokePersonInvitationMutation({
        variables: {
          id: +openDialog.id,
        },
      });
    }
  };
  const handleResendAdminInvitation = () => {
    if (openDialog.id) {
      resendPersonInvitationMutation({
        variables: {
          id: +openDialog.id,
        },
      });
    }
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Administrators" value="1" />
          <Tab label="Invitations" value="2" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={`${globalClasses.paperTitle} ${globalClasses.justifySpaceBetween}`}
          >
            <Typography variant="h5" component="h1">
              Administrators
            </Typography>
            <Button
              onClick={handleOpenCreateDialog}
              startIcon={<AddOutlinedIcon />}
              variant="outlined"
            >
              Invite new
            </Button>
          </div>
          <div className={globalClasses.paperContainer}>
            <TabPanel value="1" className={classes.tabPanel}>
              <TableContainer className={globalClasses.tableContainer}>
                <Table
                  className={globalClasses.table}
                  stickyHeader
                  // sx={{ minWidth: 650 }}
                  aria-label="List of administrators"
                >
                  <TableHead>
                    <TableRow
                    // className={classes.tableRow}
                    >
                      <TableCell width={32} align="left">
                        #
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Role</TableCell>
                      <TableCell width={180} align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                  // className={classes.tableBody}
                  >
                    {data?.allAdministrators.length
                      ? data?.allAdministrators.map((item, i) => {
                          const handleOpenDeleteDialog = () => {
                            setOpenDialog({
                              open: true,
                              id: item.id,
                              type: "delete",
                            });
                          };
                          const handleOpenEditDialog = () => {
                            setOpenDialog({
                              open: true,
                              id: item.id,
                              type: "update",
                            });
                          };

                          return (
                            <AdministratorItem
                              key={item.id}
                              index={i + 1}
                              data={item}
                              handleDelete={handleOpenDeleteDialog}
                              handleUpdate={handleOpenEditDialog}
                            />
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>

              <DataHandlerComponent
                error={Boolean(error)}
                loading={loading}
                hasData={Boolean(data?.allAdministrators.length)}
                skeletonHeight={72}
                skeletonNum={4}
              />
            </TabPanel>
            <TabPanel value="2" className={classes.tabPanel}>
              <TableContainer className={globalClasses.tableContainer}>
                <Table
                  className={globalClasses.table}
                  stickyHeader
                  // sx={{ minWidth: 650 }}
                  aria-label="List of invitations"
                >
                  <TableHead>
                    <TableRow
                    // className={classes.tableRow}
                    >
                      <TableCell width={32} align="left">
                        #
                      </TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Role</TableCell>
                      <TableCell align="left">Invited at</TableCell>
                      <TableCell align="left">Revoked at</TableCell>
                      <TableCell width={180} align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                  // className={classes.tableBody}
                  >
                    {dataInvitations?.allPersonInvitations?.length
                      ? dataInvitations?.allPersonInvitations.map((item, i) => {
                          const handleOpenRevokeDialog = () => {
                            setOpenDialog({
                              open: true,
                              id: item.id,
                              type: "revoke",
                            });
                          };
                          const handleOpenResendDialog = () => {
                            setOpenDialog({
                              open: true,
                              id: item.id,
                              type: "resend",
                            });
                          };

                          return (
                            <InvitationItem
                              key={item.id}
                              index={i + 1}
                              data={item}
                              handleResend={handleOpenResendDialog}
                              handleRevoke={handleOpenRevokeDialog}
                            />
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>

              <DataHandlerComponent
                error={Boolean(errorInvitations)}
                loading={loadingInvitations}
                hasData={Boolean(dataInvitations?.allPersonInvitations?.length)}
                skeletonHeight={72}
                skeletonNum={4}
              />
            </TabPanel>
          </div>
        </Paper>
      </TabContext>
      <DeleteDialog
        open={
          openDialog.type !== "create" && openDialog.type && openDialog.open
            ? true
            : false
        }
        title={
          openDialog.type === "delete"
            ? "Delete this admin?"
            : openDialog.type === "revoke"
            ? "Revoke this invitation?"
            : "Resend this invitation?"
        }
        description={
          openDialog.type === "delete"
            ? "This action is permanent"
            : openDialog.type === "revoke"
            ? "This user will not be able to join your organisation anymore!"
            : "Are you sure you want to resend invitation to this user and revoke the previous invitation?"
        }
        buttonTitle={
          openDialog.type === "delete"
            ? "Delete"
            : openDialog.type === "revoke"
            ? "Revoke"
            : "Resend"
        }
        onClose={handleCloseDialog}
        mutation={
          openDialog.type === "delete"
            ? handleDeleteAdmin
            : openDialog.type === "revoke"
            ? handleRevokeAdminInvitation
            : handleResendAdminInvitation
        }
      />
      <UpsertAdminDialog
        open={
          (openDialog.type === "create" || openDialog.type === "update") &&
          openDialog.open
        }
        type={openDialog.type}
        id={openDialog.id}
        onClose={handleCloseDialog}
      />
      <LoadingBackdrop
        loading={
          loadingDeleteMutation ||
          loadingResendPersonInvitation ||
          loadingRevokePersonInvitation
        }
      />
    </PageLayout>
  );
};
