import { Reference, useMutation, useQuery } from "@apollo/client";
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import {
  DELETE_CLASSIC_TOUR,
  IDeleteClassicTourData,
  IDeleteClassicTourVars,
} from "../../../apollo/mutations";
import { ALL_CLASSIC_TOURS, IClassicToursData } from "../../../apollo/queries";
import {
  PageLayout,
  DataHandlerComponent,
  LoadingBackdrop,
  Pagination,
} from "../../../components";
import { DeleteDialog } from "../../../components";
import { IDialogContext } from "../../../utils";
import { useGlobalStyles } from "../../../utils/theme";
import { useStyles } from "./ClassicToursListPage.styles";
import { NavLink } from "react-router-dom";
import { ClassicTourItem } from "./components";

export const ClassicToursListPage: FC = () => {
  const { cx } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentIndexes, setCurrentIndexes] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  // const myVal: number | number[] = 0;

  // let [minVal, maxVal] = typeof myVal == "object" ? myVal : [myVal, myVal];

  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      open: false,
      id: undefined,
    }));
  };

  const { data, loading, error } = useQuery<IClassicToursData, null>(
    ALL_CLASSIC_TOURS,
    {
      onError: (err) => {
        enqueueSnackbar(`Failed to fetch data: ${err.message}`, {
          variant: "error",
        });
      },
    }
  );

  const [deleteClassicTourMutation, { loading: loadingDeleteMutation }] =
    useMutation<IDeleteClassicTourData, IDeleteClassicTourVars>(
      DELETE_CLASSIC_TOUR,
      {
        onCompleted: (res) => {
          enqueueSnackbar("Classic tour deleted!", { variant: "success" });
          handleCloseDialog();
        },
        onError: (error) => {
          // console.log({ error });
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              allClassicTours(
                existingClassicTours: Array<Reference>,
                { readField }
              ) {
                if (data) {
                  return existingClassicTours.filter(
                    (taskRef) =>
                      data.deleteClassicTour.id !== readField("id", taskRef)
                  );
                }
              },
            },
          });
        },
      }
    );

  const handleDeleteClassicTour = () => {
    if (openDialog.id && openDialog.type === "delete") {
      deleteClassicTourMutation({ variables: { id: +openDialog.id } });
    } else {
      enqueueSnackbar("Failed to call delete mutation");
    }
  };

  const getCurrentItems = () => {
    if (data?.allClassicTours?.length) {
      const elements = [];

      const condition =
        currentIndexes.end < data.allClassicTours.length
          ? currentIndexes.end
          : data.allClassicTours.length;

      // console.log(currentIndexes, data.allClassicTours.length, condition);
      for (let i = currentIndexes.start; i < condition; i++) {
        const handleOpenDeleteDialog = () => {
          setOpenDialog({
            open: true,
            id: data.allClassicTours[i].id,
            type: "delete",
          });
        };

        elements.push(
          <ClassicTourItem
            key={data.allClassicTours[i].id}
            index={i + 1}
            data={data.allClassicTours[i]}
            handleDelete={handleOpenDeleteDialog}
          />
        );
      }
      return elements;
    }
    return null;
  };

  return (
    <PageLayout justifyCenter>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography component="h1" variant="h5">
            Classic tours
          </Typography>
          <NavLink className={globalClasses.disableNavlinkDecoration} to="new">
            <Button startIcon={<AddOutlinedIcon />} variant="outlined">
              Add new
            </Button>
          </NavLink>
        </div>
        <div className={globalClasses.paperContainer}>
          <TableContainer className={globalClasses.tableContainer}>
            <Table
              className={globalClasses.table}
              // sx={{ minWidth: 650 }}
              aria-label="List of Classic tours"
            >
              <TableHead>
                <TableRow
                // className={classes.tableRow}
                >
                  <TableCell width={32} align="left">
                    #
                  </TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell width={64} align="left">
                    Duration
                  </TableCell>
                  <TableCell width={160} align="left">
                    Created
                  </TableCell>
                  <TableCell width={64} align="left">
                    Published
                  </TableCell>
                  <TableCell width={180} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getCurrentItems()}</TableBody>
            </Table>
          </TableContainer>

          <DataHandlerComponent
            error={Boolean(error)}
            loading={loading}
            hasData={Boolean(data?.allClassicTours?.length)}
            skeletonHeight={72}
            skeletonNum={4}
          />
        </div>
        <Pagination
          data={data?.allClassicTours}
          setCurrentIndexes={setCurrentIndexes}
        />
      </Paper>

      <DeleteDialog
        open={openDialog.type === "delete" && openDialog.open}
        title="Delete this Classic tour?"
        description="This action is permanent!"
        onClose={handleCloseDialog}
        mutation={handleDeleteClassicTour}
      />
      <LoadingBackdrop loading={loadingDeleteMutation} />
    </PageLayout>
  );
};
